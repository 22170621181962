import JSEncrypt from "jsencrypt"

// 加密公钥





let key =
	'-----BEGIN PUBLIC KEY-----' +  // 必须添加，切记
	'MIGdMA0GCSqGSIb3DQEBAQUAA4GLADCBhwKBgQCQLuZweQRcw76C0EQLLYneChHcCcVjx5W+OHfDCJzU5QM2nzul1Bl6TheA1ZE9a7vcLYscPVIUevXlsI8NlxiDvitCDm5rqH5eNF8zALFYSupT3MVWL1OsOY/NRjFeygG7JzJ3WPj4L1tmNARoOWvY1kvXI/6DCGLmIw9xi4R9UQIBAw=='+  // 此处使用后端给的公钥
	'-----END PUBLIC KEY-----'   // 必须添加，切记



// 加密
const rsaEncrypt = function(msg) {
	const jsencrypt = new JSEncrypt()
	jsencrypt.setPublicKey(key)
	const encryptMsg = jsencrypt.encrypt(msg)
	return encryptMsg
}


// 解密  
const decryptData = function(msg) {
	// 新建JSEncrypt对象  
	let decrypt = new JSEncrypt();
	// 设置私钥  
	decrypt.setPrivateKey(key);
	// 解密数据  
	return decrypt.decrypt(secretWord);
}

export default {
	rsaEncrypt
}