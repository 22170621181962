import Vue from 'vue';
import VueRouter from 'vue-router';

import uploadvideo from '@/components/vploadvideo.vue';
import xzfengmian from '@/components/xzfengmian.vue'
import selecttag from '@/components/selecttag.vue'

Vue.use(VueRouter);

const routes = [{
	path: '/',
	name: 'uploadvideo',
	component: uploadvideo,
	meta: {
		keepAlive: true
	}

}, {
	path: '/xzfengmian',
	name: 'xzfengmian',
	component: xzfengmian,
	meta: {
		keepAlive: false
	}
}, {
	path: '/selecttag',
	name: 'selecttag',
	component: selecttag,
	meta: {
		keepAlive: false
	}
}];

const router = new VueRouter({
	routes
});

export default router;