<template>
	<!-- <div id="app">
		<router-view></router-view>
	</div> -->

	<div id="app">
		<keep-alive>
			<router-view v-if="$route.meta.keepAlive" />
		</keep-alive>
		<router-view v-if="!$route.meta.keepAlive" />
	</div>
</template>

<script type="text/javascript" src="https://js.cdn.aliyun.dcloud.net.cn/dev/uni-app/uni.webview.1.5.2.js"></script>
<script>
export default {
	name: 'App',
	data() {
		return {};
	},
	mounted() {
		// 接收webView 传递的信息
		// if (window.plus) {
		// 	const data = JSON.parse(plus.storage.getItem('data'));
		// }
	}
};
</script>

<style>
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
	padding: 0;
	margin: 0;
}
* {
	padding: 0;
	margin: 0;
}
::-webkit-scrollbar {
	display: none;
}
</style>
