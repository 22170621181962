<template>
	<div class="hello">
		<div class="zhanweifu"></div>
		<div class="top_content">
			<div class="left_icon" @click="gotoback">
				<img src="../assets/back.png" alt="" />
			</div>
			<div class="center_content">选择标签</div>
			<div class="save_btn" @click="sendidbox">保存</div>
		</div>
		
		<div class="inputbox">
			<div class="tipstext">
				选择标签获取更多精准推荐,同时加速审核,最多选择三个
			</div>
			<div class="tagboxbox" v-for="(item, index) in checkboxs">
					<div class="title">
						{{ item.ClassifyModel.ClassifyName }}：
					</div>
					<div class="tablist">
						<div @click="handletab(items, index)" v-for="items in item.vedioList" :class="items.IsCheck == true ? 'tab_item tab_current' : 'tab_item'">
							{{ items.LabelName }}
						</div>
					</div>
				</div>
			</div>
		
			
		

	
			
		</div>
		
		
	</div>
</template>
<script>
import axios from 'axios';
import SparkMD5 from 'spark-md5';
import rsa from '@/rsa/rsa.js';
import webview from '@/rsa/webview.js';

export default {
	name: 'HelloWorld',
	props: {
		msg: String
	},
	data() {
		return {
			uploadurl:'https://videoupload.appinfo.ltd',
			baseurl: 'https://api.appinfo.live',
			tabindex: 0,
			coverurldata: [],
			imglist: '',
			total:0,
			UserId: '',
			Token: '',
			UserVid: '',
			imgindex: 0,
			fngmianlianjie: '',
			upsuccess: false,
			checkboxs:[],
			pagebox: {
				shortVideoTitle: '',
				videoImageURL: '',
				videoPreviewURL: '',
				videoM3U8URL: '',
				duration: '',
				upAndDown: false,
				labelId: '',
				gold: ''
			},
		};
	},

	mounted() {
		this.imglist = this.$route.params.imglist;
		this.UserId = this.$route.params.UserId;
		this.Token = this.$route.params.Token;
		this.UserVid = this.$route.params.UserVid;
		
		this.getbiaoqian();
		
	},
	methods: {
		
		
		//保存
		sendidbox(){
			
			let labelIdbox = [];
			let checkboxs = this.checkboxs;
			for (let index in checkboxs) {
				const item = checkboxs[index].vedioList;
				for (let indexs in item) {
					if (item[indexs].IsCheck) {
						
						labelIdbox.push(item[indexs]);
					}
				}
			}
			this.$router.replace({
				name: 'uploadvideo',
				params: {
					ididids: labelIdbox
				}
			});
			
		},
		
		//选择标签
		handletab(itemvalue, index) {
			let checkboxs = this.checkboxs;
			for (let index in checkboxs) {
				const item = checkboxs[index].vedioList;
				for (let indexs in item) {
					if (itemvalue.LabelId == item[indexs].LabelId) {
						if (itemvalue.IsCheck) {
							this.total = this.total - 1;
							checkboxs[index].vedioList[indexs].IsCheck = !checkboxs[index].vedioList[indexs].IsCheck;
						} else {
							if (this.total < 3) {
								this.total = this.total + 1;
								checkboxs[index].vedioList[indexs].IsCheck = !checkboxs[index].vedioList[indexs].IsCheck;
							}
						}
					}
				}
			}
		},
		
		//获取视频标签
		getbiaoqian() {
			let _this = this;
			let header = '';
			let InPutData = {
				UserId: this.UserId,
				Token: this.Token,
				Type: '1'
			};
			header = {
				InPutData: rsa.rsaEncrypt(JSON.stringify(InPutData))
			};
			axios.get(this.baseurl + '/api/ShortVideo/GetVedioLabels', { headers: header }).then((res) => {
				
				if (res.data.code == 200) {
					this.checkboxs = [];
					this.checkboxs = res.data.data;
					
				}
			});
		},
		
		
		//获取文件的md5值
		fileMd5Sum(file) {
			return new Promise((resolve) => {
				let fileName = file.name.substring(0, file.name.lastIndexOf('.'));
				let fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1);
				let fileReader = new FileReader();
				let Spark = new SparkMD5.ArrayBuffer();
				fileReader.readAsArrayBuffer(file);
				fileReader.onload = function (e) {
					Spark.append(e.target.result);
					let md5 = Spark.end();
					let fileboxdata = {
						md5: md5,
						fileName: fileName,
						fileExtension: fileExtension
					};
					resolve(fileboxdata);
				};
			});
		},

		//大文件是否存在校验
		jianchefile(status, md5, uploadkey) {
			let that = this;
			return new Promise((resolve, reject) => {
				axios({
					url: that.uploadurl + '/uploads',
					method: 'get',
					params: {
						status: status,
						md5: md5,
						uploadkey: uploadkey
					}
				}).then((res) => {
					resolve(res.data);
				});
			});
		},

		//上传图片文件
		upimgfile(md5, fileitem) {
			let that = this;
			return new Promise((resolve, reject) => {
				const formData = new FormData();
				formData.append('userId', 'sdqutyeksdbv855');
				formData.append('uniqueFileName', md5);
				formData.append('chunk', 0);
				formData.append('file', fileitem);
				axios
					.post(that.uploadurl + '/uploads', formData, {
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					})
					.then((res) => {
						resolve(res);
					});
			});
		},

		//合并视屏切片
		joinvideo(md5, chunkslength, fileName, fileExtension) {
			let that = this;
			return new Promise((resolve, reject) => {
				let obj = {
					status: 'chunksMerge',
					md5: md5,
					chunks: chunkslength,
					name: md5,
					fileoldname: fileName,
					ext: fileExtension
				};
				axios({
					url: that.uploadurl + '/uploads',
					method: 'get',
					params: {
						status: 'chunksMerge',
						md5: md5,
						chunks: chunkslength,
						name: md5,
						fileoldname: fileName,
						ext: fileExtension
					}
				}).then((res) => {
					resolve(res.data);
				});
			});
		},

		//上传封面图
		async upfengmiantu() {
			//上传封面图
			if (this.coverdata) {
				const imglistfile = this.coverdata;
				for (let index in imglistfile) {
					//获取图片md5信息
					let imgmd5mesgs = await this.fileMd5Sum(imglistfile[index]);
					//检测视频是否存在 不存在就上传
					let imgfileres = await this.jianchefile('md5Check', imgmd5mesgs.md5, 'sdqutyeksdbv855');
					//上传图片
					let upimgres = await this.upimgfile(imgmd5mesgs.md5, imglistfile[index]);
					//合并上传图片
					let upimgjieguo = await this.joinvideo(imgmd5mesgs.md5, 1, imgmd5mesgs.fileName, imgmd5mesgs.fileExtension);
					if (upimgjieguo.error == 'undefined' || upimgjieguo.error == undefined) {
						//let imageURL = upimgjieguo.url;
						// let imagestr = imageURL.split('/');
						// let imageresult = '';
						// if (imagestr.length >= 3) {
						// 	for (let i = 3; i < imagestr.length; i++) {
						// 		if (imageresult !== '') {
						// 			imageresult += '/';
						// 		}
						// 		imageresult += imagestr[i];
						// 	}
						// } else {
						
						// }
						//this.pagebox.videoPreviewURL = imageresult;
						this.fngmianlianjie = upimgjieguo.url;
						

						this.upsuccess = true;
						setTimeout(() => {
							this.upsuccess = false;
						}, 2000);

						
					}
				}
			}
		},

		sendurl() {
			let imageURL;
			imageURL=this.fngmianlianjie == '' ? this.imglist[this.imgindex] : this.fngmianlianjie
			let imagestr = imageURL.split('/');
			let imageresult = '';
			if (imagestr.length >= 3) {
				for (let i = 3; i < imagestr.length; i++) {
					if (imageresult !== '') {
						imageresult += '/';
					}
					imageresult += imagestr[i];
				}
			}
			this.$router.replace({
				name: 'uploadvideo',
				params: {
					tupianlianjie: imageresult
				}
			});
		},

		setindex(e) {
			this.imgindex = e;
		},

		//删除封面图
		delcoverdata() {
			this.coverdata = [];
			this.coverurldata = [];
		},
		//获取封面文件
		handleimgfengmian(e) {
			const URL = window.URL || window.webkitURL; // 当前地址
			this.coverdata = [];
			this.coverdata.push(e.target.files[0]);
			this.coverurldata = URL.createObjectURL(e.target.files[0]);

			this.upfengmiantu();
		},

		//返回
		gotoback() {
			this.$router.back();
		}
	}
};
</script>
<style scoped>
* {
	padding: 0;
	margin: 0;
}
.hello {
	
	background-color: #090216;
	min-height: 100vh;
	width: 375px;
	.inputbox .item {
		padding: 10px 0px 5px 0px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}
}
.zhanweifu {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	height: 40px;
	background-color: #090216;
	z-index: 9999;
}
.hello .top_content {
	position: fixed;
	left: 0;
	right: 0;
	top: 35px;
	background-color: #090216;
	z-index: 999;
	height: 40px;
	display: flex;
	align-items: center;
	padding: 0px 15px;
}
.hello .left_icon {
	width: 20px;
	height: 20px;
	display: flex;
}
.hello .left_icon img {
	width: 20px;
	height: 20px;
}
.hello .center_content {
	font-size: 16px;
	font-weight: bold;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	color: #fff;
}
.hello .save_btn {
	width: 60px;
	height: 30px;
	border-radius: 5px;
	position: absolute;
	right: 20px;
	top: 50%;
	transform: translateY(-50%);
	font-size: 13px;
	white-space: nowrap;
	background: linear-gradient(90deg, #FF5F3A, #FF1C1C);
	color: #fff;
	text-align: center;
	line-height: 30px;
}

.select_img {
	width: 280px;
	height: 350px;
	margin: 10px auto;
}
.select_img img {
	width: 280px;
	height: 350px;
}

.tablist {
	display: flex;
	
	
}
.tablist .item {
	font-size: 14px;
	color: #999;
	width: 50%;
	text-align: center;
	line-height: 40px;
}

.tablist .itemed {
	font-weight: bold;
	color: #fff;
	font-size: 16px;
}

.xztupian {
	padding-top: 10px;
	display: flex;
	flex-wrap: wrap;
}
.xztupian .tp_item {
	width: 75px;
	height: 120px;
	margin-right: 7px;
	margin-bottom: 10px;
	border: 2px solid #fff;
}

.xztupian .tp_itemed {
	border: 2px solid red;
}

.xztupian .tp_item img {
	width: 75px;
	height: 120px;
}
.sc_boxboxbox {
	display: flex;
	flex-wrap: wrap;
}
.imglistbox {
	display: flex;
	flex-wrap: wrap;
	margin-top: 10px;
}
.imglistbox .img_item {
	width: 100px;
	height: 100px;
	margin-right: 10px;
	margin-bottom: 10px;
	position: relative;
}
.imglistbox .img_item img {
	width: 100px;
	height: 100px;
	border-radius: 5px;
}
.shanchuicon {
	position: absolute;
	right: 0;
	top: 0;
	width: 20px;
	height: 20px;
	display: flex;
}

.topcontent {
	width: 100px;
	height: 100px;
	font-size: 14px;
	line-height: 30px;
	cursor: pointer;
	display: inline-block;
	text-align: center;
	color: #fff;
	border-radius: 5px;
	margin-right: 10px;
}

.shagnchuangtupian {
	display: flex;
	flex-direction: column;
	padding-bottom: 15px;
	position: relative;
}

.uploadcontent {
	width: 100px;
	height: 100px;
	border-radius: 10px;
	background-color: #f5f5f5;
	position: relative;
	margin-right: 20px;
	margin-top: 10px;
}
.uploadcontent::after {
	position: absolute;
	content: '';
	width: 25px;
	height: 2px;
	background-color: gray;
	left: 50%;
	top: 40px;
	transform: translate(-50%, -50%);
}
.uploadcontent::before {
	position: absolute;
	content: '';
	width: 2px;
	height: 25px;
	background-color: gray;
	left: 50%;
	top: 40px;
	transform: translate(-50%, -50%);
}
.up_text {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	bottom: 20px;
	white-space: nowrap;
	font-size: 13px;
	color: #999;
	z-index: 99;
}

.hello ::-webkit-scrollbar {
	display: none; /* Chrome Safari */
}

.successtext {
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	font-size: 16px;
	padding: 10px 20px;
	background-color: rgba(0, 0, 0, 0.6);
	color: #fff;
	border-radius: 5px;
}

.inputbox {
	padding-top: 100px;
	padding-bottom: 20px;
}
.inputbox .item {
	padding: 10px 0px 5px 0px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}
.inputbox .item .title {
	font-size: 16px;
	text-align: left;
	color: #000;
	font-weight: bold;
	padding-left: 15px;
	position: relative;
}
.inputbox .item .title ::after {
	content: '';
	position: absolute;
	left: 8px;
	top: 4px;
	height: 13px;
	width: 2px;
	background-color: red;
}
.inputbox .item .title span {
	font-size: 12px;
	color: #999;
	font-weight: normal;
}
.upload_box {
	display: flex;
	justify-content: space-around;
}
.upload_box .item {
	width: 160px;

	border: 1px dashed #ccc;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	position: relative;
	padding-top: 10px;
	padding-bottom: 10px;
}
.upload_box .itembg {
	width: 40px;
	height: 40px;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}
.upload_box .itembg img {
	width: 40px;
	height: 40px;
}
#addFileBtnSpan {
	font-size: 14px;
	margin-top: 8px;
	width: 160px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;
	font-size: 12px;
}
.upload_box .hengzhe {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.upload_box .hengzhe p {
	font-size: 16px;
}

.upload_box .hengzhe .p1 {
	color: #000;
	margin-bottom: 10px;
}
.upload_box .hengzhe .p2 {
	color: #999;
}

.upload_box .hengzhe .p3 {
	color: #000;
	margin-bottom: 10px;
	font-size: 16px;
}
.upload_box .hengzhe .p4 {
	color: #999;
	font-size: 12px;
}

.top {
	width: 160px;
	height: 50px;
	opacity: 0;
	font-size: 14px;
	line-height: 30px;
	cursor: pointer;
	display: inline-block;
	text-align: center;
	color: #fff;
	border-radius: 5px;
	border: 1px solid #666666;
}
.topFile {
	height: 30px;
	width: 95px;
	background-color: floralwhite;
	font-size: 14px;
	line-height: 30px;
	cursor: pointer;
	display: inline-block;
	text-align: center;
	border-radius: 5px;
	margin-left: 15px;
}

.neirong {
	display: flex;
	padding: 10px;
	border-bottom: 1px solid #eee;
}
.neirong .biaoti {
}
.biaoti textarea {
	height: 120px;
	width: 210px;
	padding: 5px;
	border: none;
	outline: none;
	resize: none;
}
.neirong .fengmiantu {
	width: 100px;
	height: 130px;

	margin-left: 10px;
	position: relative;
}
.fmimg {
	width: 100px;
	height: 130px;
}
.xuanzhetupian {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	height: 30px;
	line-height: 30px;
	text-align: center;
	font-size: 13px;
	background-color: rgba(0, 0, 0, 0.6);
	color: #fff;
}

.inputbox {
}
.inputbox .item {
	padding: 10px 0px 5px 0px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}
.inputbox .item .title {
	font-size: 16px;
	text-align: left;
	color: #000;
	font-weight: bold;
	padding-left: 15px;
	position: relative;
}
.inputbox .item .title ::after {
	content: '';
	position: absolute;
	left: 8px;
	top: 4px;
	height: 13px;
	width: 2px;
	background-color: red;
}
.inputbox .item .title span {
	font-size: 12px;
	color: #999;
	font-weight: normal;
}

.select_tag {
	margin-top: 15px;
	padding-top: 15px;
	border-top: 1px solid #eee;
	height: 30px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.select_tag .s_title {
	font-size: 16px;
	text-align: left;
	color: #000;
	font-weight: bold;
	padding-left: 15px;
	position: relative;
}
.select_tag .s_title span{
	font-size: 12px;
	color: #999;
	font-weight: normal;
}

.select_tag .s_title::after {
	content: '';
	position: absolute;
	left: 8px;
	top: 4px;
	height: 13px;
	width: 2px;
	background-color: red;
}

.select_tag .s_icon {
	width: 15px;
	height: 15px;
	display: flex;
}

.select_tag .s_icon img{
	width: 15px;
	height: 15px;
}

.inputcontent {
	width: 330px;
	height: 30px;
	margin-left: 5px;
	padding-left: 10px;
	border: 1px solid #eee;
	border-radius: 5px;
	background-color: #f5f5f5;
}

.biaoqian {
	height: 35px;
	background-color: #eee;
	margin-left: 5px;
	margin-right: 15px;
	border-radius: 5px;

	position: relative;
	span {
		position: absolute;
		left: 15px;
		font-size: 13px;
		top: 50%;
		transform: translateY(-50%);
		color: #999;
	}
	.biaoqiaoleft {
		position: absolute;
		width: 20px;
		height: 20px;
		right: 5px;
		top: 50%;
		transform: translateY(-50%);
		img {
			width: 20px;
			height: 20px;
		}
	}
}

.tipsbox {
	display: flex;
	flex-direction: column;
	padding-top: 20px;
}
.tipsbox p {
	font-size: 11px;
	color: #999;
	text-align: left;
	line-height: 20px;
	display: flex;
	align-items: center;
}
.tipsbox p img {
	width: 12px;
	height: 12px;
	margin-left: 2px;
	display: flex;
}

.upbtn {
	height: 44px;
	border-radius: 16px;
	width: 331px;
	text-align: center;
	line-height: 44px;
	color: #fff;
	font-size: 16px;
	margin: 30px auto 0 auto;
	background-image: linear-gradient(#04df97, #00b3da);
}
.fabushortvideo {
	height: 44px;
	border-radius: 16px;
	width: 331px;
	text-align: center;
	line-height: 44px;
	color: #fff;
	font-size: 16px;
	margin: 16px auto 0 auto;
	background-image: linear-gradient(#04df97, #00b3da);
}
.tipstext{
	font-size: 16px;
	text-align: center;
	padding-top: 5px;
	color: #999;
	padding-left: 20px;
	padding-right: 10px;
}
.tagboxbox {
	display: flex;
	flex-direction: column;
}
.tagboxbox .title {
	text-align: left;
	font-size: 19px;
	font-weight: bold;
	color: red;
	padding-bottom: 5px;
	padding-left: 5px;
	padding-top: 10px;
	
	color: #999;
	line-height: 20px;
	
}
.tablist {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	padding-left: 5px;
	overflow-y: hidden;
	/* height: 263px; */
}
.tablistall {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	padding-left: 5px;
	overflow-y: hidden;
	height: auto;
}
.tablisthiden {
	height: 265px;
}
.tablist .tab_item {
	padding: 0 7px;
	height: 25px;
	text-align: center;
	line-height: 25px;
	border-radius: 5px;
	font-size: 12px;
	text-align: center;
	border: 1px solid #999;
	margin-right: 5px;
	margin-top: 6px;
	color: #999;
	
}
.tablist .tab_current {
	background-color: #FF1C1C;
	color: #fff;
}
.zhankai {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	font-size: 13px;
	flex-direction: row;
	margin: 10px auto 0 auto;
}
.zhankai span {
	width: 12px;
	height: 12px;
	display: flex;
	margin-right: 4px;
}
.zhankai span img {
	width: 12px;
	height: 12px;
}
.uplist {
	display: flex;
	flex-wrap: wrap;
}
.uplist .up_item {
	width: 60px;
	height: 100px;
	margin-top: 10px;
	margin-left: 5px;
	border: 1px solid #fff;
}
.uplist .select_item {
	border: 1px solid red;
}
.uplist .up_item img {
	width: 60px;
	height: 100px;
}

.popus {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.6);
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	font-size: 16px;
	flex-direction: column;
}
.popus .jingdutiao {
	width: 250px;
	height: 12px;
	border: 1px solid #fff;
	border-radius: 5px;
	position: relative;
	overflow: hidden;
}
.popus .jingdutiao span {
	display: block;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: gold;
}
.transcoding {
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	font-size: 16px;
	padding: 10px 20px;
	background-color: rgba(0, 0, 0, 0.6);
	color: #fff;
	border-radius: 5px;
}
.uptipstext {
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	font-size: 16px;
	padding: 10px 20px;
	background-color: rgba(0, 0, 0, 0.6);
	color: #fff;
	border-radius: 5px;
}
.successtext {
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	font-size: 16px;
	padding: 10px 20px;
	background-color: rgba(0, 0, 0, 0.6);
	color: #fff;
	border-radius: 5px;
}
.fabutext {
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	font-size: 16px;
	padding: 10px 20px;
	background-color: rgba(0, 0, 0, 0.6);
	color: #fff;
	border-radius: 5px;
}
.errortext {
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	font-size: 16px;
	padding: 10px 20px;
	background-color: rgba(0, 0, 0, 0.6);
	color: #fff;
	border-radius: 5px;
}

.sc_boxboxbox {
	display: flex;
	flex-wrap: wrap;
	margin-top: 10px;
}
.imglistbox {
	display: flex;
	flex-wrap: wrap;
}
.imglistbox .img_item {
	width: 100px;
	height: 100px;
	margin-right: 10px;
	margin-bottom: 10px;
	position: relative;
}
.imglistbox .img_item img {
	width: 100px;
	height: 100px;
	border-radius: 5px;
}
.shanchuicon {
	position: absolute;
	right: 0;
	top: 0;
	width: 20px;
	height: 20px;
	display: flex;
}

.topcontent {
	width: 100px;
	height: 100px;
	font-size: 14px;
	line-height: 30px;
	cursor: pointer;
	display: inline-block;
	text-align: center;
	color: #fff;
	border-radius: 5px;
}

.uploadcontent {
	width: 100px;
	height: 100px;
	border-radius: 10px;
	background-color: #f5f5f5;
	position: relative;
	margin-right: 20px;
}
.uploadcontent::after {
	position: absolute;
	content: '';
	width: 25px;
	height: 2px;
	background-color: gray;
	left: 50%;
	top: 40px;
	transform: translate(-50%, -50%);
}
.uploadcontent::before {
	position: absolute;
	content: '';
	width: 2px;
	height: 25px;
	background-color: gray;
	left: 50%;
	top: 40px;
	transform: translate(-50%, -50%);
}

.up_text {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	bottom: 20px;
	white-space: nowrap;
	font-size: 13px;
	color: #999;
	z-index: 99;
}
.selected_video {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	bottom: 0px;
	white-space: nowrap;
	font-size: 12px;
	z-index: 99;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	width: 80px;
	color: red;
}

</style>
